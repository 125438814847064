import React, { useEffect, useState } from "react";
import { VinSearchCtxProvider } from "components/VinSearch/VinSearchContext";
import { ModuleGeneralVinSearch } from "../../../contentful/PageModularModule";
import VinSearch, { SearchDisplayType } from "components/VinSearch/VinSearch";
import ModalWhereIsVin from "../ModalWhereIsVin";
import { VinSearchComponent } from "@carfax/web-ui/components/VinSearchComponent";
import { VinSearchType } from "@carfax/web-ui/components/VinSearchComponent/utils/useSettings";
import PartnerHeader from "../PartnerHeader";
import NoLicensePlateHeaderModule from "../NoLicensePlateHeaderModuleWrapper";
import { Locales } from "i18n/country";
import { useTranslation } from "react-i18next";
import { useOpenDemoIframe } from "hooks/useOpenDemoIframe";

interface GeneralVinSearchProps {
  module: ModuleGeneralVinSearch;
}

const GeneralVinSearch: React.FC<GeneralVinSearchProps> = ({
  module: {
    header,
    bottomSubtitle,
    placeholder,
    subtitle,
    componentType,
    partnerLogo,
    headerWithoutVin,
    subtitleWithoutVin,
    bottomSubtitleUrl,
    partnerNote,
  },
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { modal } = useOpenDemoIframe();
  const { i18n, t } = useTranslation(["common"]);

  const onClickWhereIsVin = (event: any) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const vinSearchProps = {
    displayType: SearchDisplayType.DefaultWithoutContainer,
    customPlaceholder: placeholder,
  };
  const [clientBottomSubtitle, setClientBottomSubtitle] = useState<string | React.ReactNode>(bottomSubtitle);

  useEffect(() => {
    if (i18n.language === Locales.SPANISH) {
      setClientBottomSubtitle(<div onClick={() => modal.open()}>{t("common:demo.demoTourCta")}</div>);
    }
  }, [i18n.language]);

  const vinSearchComponentProps = {
    header: header,
    subtitle: subtitle,
    bottomSubtitle: clientBottomSubtitle,
    openModal: onClickWhereIsVin,
    componentType: componentType,
    bottomSubtitleUrl: bottomSubtitleUrl,
  };

  const partnerHeaderProps = {
    title: header,
    titleWithoutVin: headerWithoutVin,
    partnerLogo: partnerLogo,
    enterVinMessage: subtitle,
    enterWithoutVinMessage: subtitleWithoutVin,
    afterVinCheck: bottomSubtitle,
  };

  const noLicensePlateHeaderProps = {
    title: header,
    partnerLogo: partnerLogo,
    partnerNote: partnerNote,
    bottomSubtitle: bottomSubtitle,
  };

  const renderHeader = () => {
    switch (componentType) {
      case VinSearchType.NO_LICENSE_PLATE_HEADER:
        return <NoLicensePlateHeaderModule {...noLicensePlateHeaderProps} />;
      case VinSearchType.PARTNER_HEADER_WITH_LOGO:
        return <PartnerHeader module={partnerHeaderProps} />;
      default:
        return renderVinSearchComponent();
    }
  };

  const renderVinSearchComponent = () => {
    return (
      <>
        <VinSearchComponent {...vinSearchComponentProps}>
          <VinSearchCtxProvider values={{ status: null, errorMsg: null }}>
            <VinSearch {...vinSearchProps} />
          </VinSearchCtxProvider>
        </VinSearchComponent>

        {modal.isSpanish
          ? modal.demo
          : modalOpen && <ModalWhereIsVin modalOpen={modalOpen} setModalOpen={setModalOpen} />}
      </>
    );
  };

  return renderHeader();
};

export default GeneralVinSearch;
