import { ModuleBolloCalculator } from "../../../contentful/PageModularModule";
import React, { useState } from "react";
import { useTranslation } from "../../../app";
import BolloComponent from "@carfax/web-ui/components/BolloComponent";
import { InputStatus } from "@carfax/web-ui/components/BolloComponent/BolloCalculator/VinCheckInput";
import RecaptchaActions from "domain/Recaptcha/actions";
import { VinSearchData } from "@carfax/web-ui/components/BolloComponent/BolloComponent";
import { PricingApi } from "utils/pricing-response/pricing-api";
import { dataLayer } from "./utils/dataLayer";

export interface BolloCalculatorModuleInterface {
  module: ModuleBolloCalculator;
}

const BolloCalculatorModule: React.FC<BolloCalculatorModuleInterface> = ({ module }) => {
  const { t } = useTranslation(["common"]);
  const [inputStatus, setInputStatus] = useState(InputStatus.DEFAULT);
  const [vinSearchData, setVinSearchData] = useState<VinSearchData | null>(null);

  const handleVinCheck = async (vinNumber: string) => {
    setInputStatus(InputStatus.LOADING);
    const pricingApi = new PricingApi();
    try {
      const data = (await pricingApi.fetchEngineDataResponseWithCaptcha(
        vinNumber,
        RecaptchaActions.vinsearch
      )) as VinSearchData;
      if (data?.enginePowerKW === null || data?.euEmissionStandard === null) {
        setInputStatus(InputStatus.ERROR);
      } else {
        setVinSearchData(data);
        setInputStatus(InputStatus.SUCCESS);
      }
    } catch (e) {
      setInputStatus(InputStatus.ERROR);
    }
  };

  const translations = {
    regionText: t("bolloComponent.regionText"),
    regionPlaceholder: t("bolloComponent.regionPlaceholder"),
    euroNormText: t("bolloComponent.euroNormText"),
    euroNormPlaceholder: t("bolloComponent.euroNormPlaceholder"),
    enginePowerText: t("bolloComponent.enginePowerText"),
    enginePowerPlaceholder: t("bolloComponent.enginePowerPlaceholder"),
    calculateText: t("bolloComponent.calculateText"),
    summaryText: t("bolloComponent.summaryText"),
    errorMessage: t("bolloComponent.errorMessage"),
    vinCheckLabelText: t("bolloComponent.vinPlaceholder"),
    vinCheckErrorMessage: t("bolloComponent.vinCheckErrorMessage"),
    tabTexts: { tab1: t("bolloComponent.tab1"), tab2: t("bolloComponent.tab2") },
  };

  const moduleData = {
    heading: module.heading,
    subtitle: module.subtitle,
    information: module.information,
    isHeadingH2: module.isHeadingH2,
    hideVinSearchBollo: module.hideVinSearchBollo,
  };

  return (
    <>
      <BolloComponent
        moduleData={moduleData}
        translations={translations}
        inputStatus={inputStatus}
        vinSearchData={vinSearchData}
        setInputStatus={setInputStatus}
        handleVinCheck={handleVinCheck}
        dataLayer={dataLayer}
      />
    </>
  );
};

export default BolloCalculatorModule;
