import { useState } from "react";
import { styles } from "./PageDemo.style";
import { Locales } from "i18n/country";
import { dataLayerEvents } from "domain/SeoAndAnalytics/GoogleAnalytics/dataLayerEvents";
import { useTranslation } from "app";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div css={styles.modalWrapper()} onClick={onClose}>
      <div css={styles.modalContent()} onClick={(e) => e.stopPropagation()}>
        {children}
        <div css={styles.closeButton()} onClick={onClose}>
          ✖
        </div>
      </div>
    </div>
  );
};

const DemoIframe = () => (
  <div css={styles.iframeWrapper()}>
    <iframe
      loading="lazy"
      className="sl-demo"
      src="https://app.storylane.io/demo/bg1q1ckjzdja?embed=inline"
      name="sl-embed"
      allow="fullscreen"
      allowFullScreen
      css={styles.iframeComponent()}
    ></iframe>
  </div>
);

export const useOpenDemoIframe = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const isSpanish = i18n.language === Locales.SPANISH;

  const openModal = () => {
    dataLayerEvents.submitViewSampleReportEvent();
    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  const modalDemo = (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <DemoIframe />
    </Modal>
  );

  return { modal: { open: openModal, demo: modalDemo, isSpanish } };
};
