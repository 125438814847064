import React, { Dispatch, SetStateAction } from "react";
import { styles } from "../BolloCalculator.style";

interface SelectValueProps {
  setSelectValue: Dispatch<SetStateAction<string>>;
  table: { name: string }[];
}

const SelectValue: React.FC<SelectValueProps> = ({ setSelectValue, table = [] }) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, value: string) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setSelectValue(value);
    }
  };

  return (
    <>
      {table.map((item) => (
        <li
          onKeyDown={(event) => onKeyDown(event, item.name)}
          tabIndex={-1}
          key={item.name}
          onClick={() => setSelectValue(item.name)}
          css={styles.option()}
        >
          {item.name}
        </li>
      ))}
    </>
  );
};
export default SelectValue;
