import Loading from "components/Accessories/Loading";
import { useTranslation } from "app";
import { colors } from "@carfax/web-ui/core";

import { styles } from "./ContentLoading.style";

interface Props {
  size?: number;
  color?: string;
  loadingText?: string;
  loading?: boolean;
  stretchContainer?: boolean;
}

const ContentLoading: React.FC<Props> = (props) => {
  const { loadingText, color, loading, size = 10, stretchContainer = true } = props;
  const { t } = useTranslation(["pages-record-check"]);
  const txt = loadingText || t("preview.loadingTxt");
  const loadingColor = color || colors.paleBlue.hex;

  return (
    <div css={[styles.loadingWrap(stretchContainer)]}>
      <h2 css={styles.loadingTxt()}>{txt}</h2>
      <Loading color={loadingColor} size={size} type={"BarLoader"} loading={loading} />
    </div>
  );
};

export default ContentLoading;
