import { css, keyframes } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../core/styles";
import { Icons } from "../Icon";
const SELECT_INPUT_SMALL_HEIGHT = "38px";
const SELECT_INPUT_HEIGHT = "54px";

const SELECT_INPUT_TOP_PADDING = `calc((${SELECT_INPUT_HEIGHT} - ${lineHeights.body.horse})/2)`;
const SELECT_INPUT_SMALL_TOP_PADDING = `calc((${SELECT_INPUT_SMALL_HEIGHT} - ${lineHeights.body.horse})/2)`;

const fadeInAnimation = keyframes`
  0%  { opacity: 0; transform: translateY(-8px); };
  75% { opacity: 1 };
  100% { opacity: 1 ; transform: translateY(0); }`;

const fadeOutAnimation = keyframes`
  0%  { opacity: 1; transform: translateY(0) };
  25% { opacity: 0 };
  100% { opacity: 0; transform: translateY(-8px); }`;

export const styles = {
  root: (isShort: boolean) =>
    css({
      width: "100%",
      position: !isShort ? "relative" : "unset",
      [mediaQuery.largerThanMobile]: {
        width: isShort ? "fit-content" : "100%",
      },
    }),
  form: () =>
    css({
      position: "relative",
      minWidth: "118px",
      "&:focus-within": {
        fieldset: {
          border: `1px solid ${colors.linkBlue.hex}`,
        },
        label: {
          color: colors.linkBlue.hex,
        },
        input: {
          color: colors.headlineGrey.hex,
          outline: "none",
        },
        "& > div": {
          color: colors.linkBlue.hex,
        },
      },
    }),

  withError: () =>
    css({
      "& label, & > div, & select": {
        color: colors.red.hex,
      },
      "& fieldset": {
        borderColor: colors.red.hex,
      },
    }),

  disabled: () =>
    css({
      "& label": {
        color: colors.lightGrey.hex,
      },
      "& fieldset": {
        backgroundColor: colors.backgroundGrey.hex,
      },
    }),

  inputContainer: () =>
    css({
      position: "relative",
      height: "100%",
      gridArea: "select",
    }),

  input: () =>
    css({
      transition: "color .2s ease-in-out",
      width: "100%",
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      margin: 0,
      border: "none",
      paddingTop: "22px",
      paddingBottom: "8px",
      paddingRight: 0,
      paddingLeft: spacing.baseUnitMobile2,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      "&:focus": {
        outline: 0,
      },
      "&::placeholder": {
        color: colors.lightGrey.hex,
      },
      "&::-ms-reveal": {
        display: "none",
      },
    }),

  labelText: (frontIcon: boolean) =>
    css({
      transition: "color .2s ease-in-out",
      position: "absolute",
      top: spacing.baseUnitMobile1,
      left: frontIcon ? 0 : spacing.baseUnitMobile2,
      color: colors.stdTextGrey.hex,
      lineHeight: 1,
      height: "14px",
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
    }),

  placeholderText: (isShort: boolean) =>
    css(
      isShort
        ? {
            margin: `${spacing.baseUnitMobile1} 0`,
            fontSize: fontSizes.body.cat,
            letterSpacing: letterSpacings.body.cat,
            color: colors.headlineGrey.hex,
          }
        : {
            margin: `${spacing.baseUnitMobile3} ${spacing.baseUnitMobile2}`,
            fontSize: fontSizes.body.horse,
            letterSpacing: letterSpacings.body.horse,
            color: colors.headlineGrey.hex,
          }
    ),

  select: (frontIcon: boolean) =>
    css({
      cursor: "pointer",
      transition: "color .2s ease-in-out",
      width: "100%",
      backgroundColor: "transparent",
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      border: "none",
      padding: `14px 0 ${spacing.baseUnitMobile1}`,
      paddingLeft: frontIcon ? 0 : spacing.baseUnitMobile2,
      borderRadius: "4px",
      appearance: "none",

      "&:focus": {
        outline: 0,
      },
      "&::placeholder": {
        color: colors.lightGrey.hex,
      },
    }),

  content: (isShort: boolean, showSelectSpace: boolean) =>
    css({
      cursor: "pointer",
      display: "grid",
      gridTemplateAreas: `
      'frontIcon select'
      `,
      gridTemplateColumns: "max-content auto",
      borderRadius: "4px",
      backgroundColor: colors.white.hex,
      border: `1px solid ${isShort ? colors.disabledGrey.hex : colors.lightGrey.hex}`,
      borderColor: showSelectSpace && colors.backgroundBlue.hex,
      height: isShort ? SELECT_INPUT_SMALL_HEIGHT : SELECT_INPUT_HEIGHT,
      transition: "border-color .2s ease-in-out",
      boxSizing: "content-box",
      "&:focus-visible": {
        outline: `none`,
      },
    }),

  info: (show: boolean) =>
    css({
      transition: "color .2s ease-in-out",
      color: colors.stdTextGrey.hex,
      lineHeight: 1.2,
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      width: "100%",
      padding: `4px ${spacing.baseUnitMobile2} 0`,
      height: "22px",
      animation: show ? `.2s forwards ${fadeInAnimation}` : `.2s forwards ${fadeOutAnimation}`,
    }),

  selectArrow: (isShort: boolean, showSelectSpace: boolean) =>
    css({
      transition: "transform 0.3s ease",
      background: `no-repeat center/50% url(${Icons.arrowDropDown})`,
      position: "absolute",
      height: "24px",
      width: "24px",
      right: isShort ? spacing.baseUnitMobile1 : spacing.baseUnitMobile2,
      top: isShort ? SELECT_INPUT_SMALL_TOP_PADDING : SELECT_INPUT_TOP_PADDING,
      transform: !showSelectSpace && "rotate(180deg)",
      pointerEvents: "none",
    }),

  selectSpace: (isShort: boolean, showSelectSpace: boolean) =>
    css({
      marginTop: spacing.baseUnitMobile1,
      boxShadow: "0 2px 25px 0 rgba(0,0,0,0.1)",
      visibility: showSelectSpace ? "visible" : "hidden",
      padding: `${spacing.baseUnitMobile1} ${spacing.baseUnitMobile2}`,
      backgroundColor: colors.white.hex,
      borderRadius: spacing.baseUnitMobile1,
      zIndex: 9999,
      transform: isShort ? "translateY(8px)" : "translateY(-24px)",
      userSelect: "none",
      position: "absolute",
      width: "100%",
      maxHeight: !isShort && "180px",
      overflow: "auto",
      [mediaQuery.largerThanMobile]: {
        width: isShort && "fit-content",
      },
      li: {
        color: colors.headlineGrey.hex,
        margin: `${spacing.baseUnitMobile1} 0 ${spacing.baseUnitMobile1} 0`,
        "&:hover": {
          cursor: "pointer",
          fontWeight: 700,
        },
      },
    }),

  frontIcon: () =>
    css({
      gridArea: "frontIcon",
      display: "flex",
      alignSelf: "center",
      border: "none",
      backgroundColor: "transparent",
      margin: 0,
      svg: {
        marginRight: spacing.baseUnitMobile1,
        marginLeft: spacing.baseUnitMobile2,
        width: "16px",
        height: "16px",
      },
    }),
};
