import { colors, fontSizes, spacing } from "@carfax/web-ui";
import { css } from "@emotion/react";

export const styles = {
  modalWrapper: () =>
    css({
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }),
  modalContent: () =>
    css({
      position: "relative",
      width: "100%",
      margin: `0 ${spacing.baseUnitMobile2}`,
      maxWidth: "600px",
    }),
  closeButton: () =>
    css({
      position: "absolute",
      top: "2px",
      right: "9px",
      fontSize: fontSizes.heading.H1.mobile,
      color: colors.darkGrey.hex,
      cursor: "pointer",
      zIndex: 1001,
    }),
  iframeWrapper: () => css({ position: "relative", paddingBottom: "calc(126.42% + 25px)", width: "100%", height: 0 }),
  iframeComponent: () =>
    css({
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      boxShadow: "0px 0px 18px rgba(26, 19, 72, 0.15)",
      borderRadius: "10px",
      boxSizing: "border-box",
      zIndex: 1000,
    }),
};
