import React, { useState, useEffect } from "react";
import { styles } from "./BolloCalculator.style";
import { SelectInput } from "../../../elements/SelectInput";
import { Input } from "../../../elements/Input";
import { Button } from "../../../elements/Button";
import { taxConfig } from "../taxConfig";
import { usePiemonteRegionCalculator } from "./utils/usePiemonteRegionCalculator";
import { bolloCalculatorUtil } from "./utils/bolloCalculatorUtils";
import { useErrorHandler } from "./utils/useErrorHandler";

import { InputStatus, VinCheckInput } from "./VinCheckInput/VinCheckInput";
import { DataLayer, Translations, VinSearchData } from "../BolloComponent";
import { SelectValue } from "./SelectValue";
import { useDelay } from "./utils/useDelay";

export interface BolloCalculatorProps {
  translations: Translations;
  vinCheckBollo: boolean;
  inputStatus: string;
  vinSearchData: VinSearchData;
  dataLayer: DataLayer;
  setInputStatus: (inputStatus: InputStatus) => void;
  handleVinCheck: (vinNumber: string) => Promise<void>;
}

export const enum EnginePower {
  EQUAL_100KW = 100,
  EQUAL_131KW = 131,
  EQUAL_51KW = 51,
}

export const BolloCalculator: React.FC<BolloCalculatorProps> = ({
  translations,
  vinCheckBollo,
  inputStatus,
  vinSearchData,
  handleVinCheck,
  setInputStatus,
  dataLayer,
}) => {
  const {
    regionText,
    euroNormText,
    enginePowerText,
    calculateText,
    summaryText,
    enginePowerPlaceholder,
    euroNormPlaceholder,
    regionPlaceholder,
    errorMessage,
    vinCheckLabelText,
    vinCheckErrorMessage,
  } = translations;

  const basicValue = { name: "Euro 0", value: 0 };
  const [regionName, setRegionName] = useState(regionPlaceholder);
  const [euroNorm, setEuroNorm] = useState(basicValue);
  const [enginePower, setEnginePower] = useState(0);
  const [calculatedTax, setCalculatedTax] = useState(0);
  const [additionalTax, setAdditionalTax] = useState(0);
  const [euroNormPlaceholderValue, setEuroNormPlaceholderValue] = useState(euroNormPlaceholder);
  const [isEnginePowerTouched, setIsEnginePoweTouched] = useState(false);
  const isDisabled = inputStatus === InputStatus.LOADING || inputStatus === InputStatus.ERROR || enginePower < 1;
  const { check, handle } = useErrorHandler(
    regionName,
    euroNorm,
    enginePower,
    regionPlaceholder,
    euroNormPlaceholderValue,
    euroNormPlaceholder,
    !!vinSearchData
  );
  const { piemonteCalculator, isPiemonteRegion, additionalTaxTable, maxEnginePower100 } = usePiemonteRegionCalculator(
    enginePower,
    regionName
  );

  const basicGeneralRegion = regionName === regionPlaceholder ? taxConfig[0].name : regionName;
  const euroNormForRegion = bolloCalculatorUtil.getValueFromArray(taxConfig, basicGeneralRegion);

  const euroNormTable = () => {
    if (regionName !== regionPlaceholder) {
      if (isPiemonteRegion) {
        return piemonteCalculator();
      }
      return euroNormForRegion.below100KW;
    }
    return taxConfig[0].below100KW;
  };

  const calculateTax = () => {
    if (enginePower < EnginePower.EQUAL_100KW && isPiemonteRegion) {
      return Math.round(euroNorm.value * enginePower);
    }
    return Math.round(maxEnginePower100 * euroNorm.value + additionalTax);
  };

  const changeEnginePower = (e) => {
    if (regionName !== regionPlaceholder) {
      setEnginePower(e.target.value);
      setIsEnginePoweTouched(true);
    }
    return null;
  };

  const resetFields = () => {
    handle.resetErrors();
    setEuroNorm(basicValue);
    setCalculatedTax(0);
    setRegionName(regionPlaceholder);
    setInputStatus(InputStatus.DEFAULT);
    setEnginePower(0);
    setEuroNormPlaceholderValue(euroNormPlaceholder);
  };

  useEffect(() => {
    resetFields();
  }, [vinCheckBollo]);

  useEffect(() => {
    if (enginePower > EnginePower.EQUAL_100KW) {
      const taxTable = isPiemonteRegion ? additionalTaxTable() : euroNormForRegion?.above100KW;
      setAdditionalTax(
        (enginePower - EnginePower.EQUAL_100KW) * bolloCalculatorUtil.getValueFromArray(taxTable, euroNorm.name).value
      );
    } else if (enginePower < EnginePower.EQUAL_100KW) {
      setAdditionalTax(0);
    }
  }, [enginePower]);

  useEffect(() => {
    if (euroNorm != basicValue) {
      setEuroNorm(bolloCalculatorUtil.getValueFromArray(euroNormTable(), euroNorm.name));
    }
  }, [enginePower, regionName]);

  useEffect(() => {
    if (vinCheckBollo && vinSearchData) {
      setEuroNorm({
        name: bolloCalculatorUtil.adjustEuroStandard(vinSearchData.euEmissionStandard),
        value: bolloCalculatorUtil.getValueFromArray(
          euroNormTable(),
          bolloCalculatorUtil.adjustEuroStandard(vinSearchData.euEmissionStandard)
        ).value,
      });
      setEnginePower(vinSearchData.enginePowerKW || vinSearchData.motorPowerKW);
    }
  }, [vinSearchData]);

  const sendDataLayerEvent = (options) => {
    const { euroNormError, enginePowerError, regionError, vinSearchError } = options;

    const errorResult = [
      regionError ? "region" : null,
      euroNormError ? "euro_norm" : null,
      enginePowerError ? "kw" : null,
      vinSearchError ? "vin" : null,
    ];
    const filteredErrors = [...new Set(errorResult.filter(Boolean))];

    if (filteredErrors.length > 0) {
      dataLayer.errorEvent(filteredErrors.join(", "), vinCheckBollo);
    } else {
      dataLayer.successEvent(vinCheckBollo);
    }
  };

  const handleOnClick = () => {
    setCalculatedTax(calculateTax());
    handle.setValidationPerformed(true);
    if (vinCheckBollo) {
      handle.validateVinSearchFields(sendDataLayerEvent);
    } else {
      handle.validateFields(sendDataLayerEvent);
    }
  };

  const onKeyDownEuroNormSelection = (
    event: React.KeyboardEvent<HTMLLIElement>,
    value: { name: string; value: number }
  ) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setEuroNorm(value);
      setEuroNormPlaceholderValue(value.name);
    }
  };

  return (
    <div css={styles.root()}>
      <div css={styles.wrapper()}>
        {useDelay(vinCheckBollo, 300) ? (
          <>
            <VinCheckInput
              testId="vin-check-input"
              hasError={check.vinCheckHasError}
              vinCheckLabelText={vinCheckLabelText}
              vinCheckErrorMessage={vinCheckErrorMessage}
              message={errorMessage}
              inputStatus={inputStatus}
              handleVinCheck={handleVinCheck}
            />
            <SelectInput
              testId="select-region-vinCheck"
              label={regionText}
              hideAfterSelect={true}
              placeholder={regionName}
              hasError={check.regionVinSearchHasError}
              message={check.regionVinSearchHasError && errorMessage}
            >
              <SelectValue setSelectValue={setRegionName} table={taxConfig} />
            </SelectInput>
          </>
        ) : (
          <>
            <SelectInput
              testId="select-region"
              label={regionText}
              hideAfterSelect={true}
              placeholder={regionName}
              hasError={check.regionHasError}
              message={check.regionHasError && errorMessage}
            >
              <SelectValue setSelectValue={setRegionName} table={taxConfig} />
            </SelectInput>
            <SelectInput
              testId="select-euroNorm"
              label={euroNormText}
              hideAfterSelect={true}
              placeholder={euroNormPlaceholderValue}
              hasError={check.euroNormHasError}
              message={check.euroNormHasError && errorMessage}
            >
              {euroNormTable()?.map((value) => (
                <li
                  tabIndex={-1}
                  key={value.name}
                  onClick={() => {
                    setEuroNorm(value);
                    setEuroNormPlaceholderValue(value.name);
                  }}
                  onKeyDown={(event) => onKeyDownEuroNormSelection(event, value)}
                  css={styles.option()}
                >
                  {value.name}
                </li>
              ))}
            </SelectInput>
            <Input
              testId="engine-power-input"
              labelText={enginePowerText}
              onChange={(e) => changeEnginePower(e)}
              type="number"
              min="1"
              max="999"
              placeholder={enginePowerPlaceholder}
              hasError={check.enginePowerHasError}
              message={check.enginePowerHasError && errorMessage}
            />
          </>
        )}
        <Button
          testSelector="calculate-button"
          css={styles.button()}
          onClick={handleOnClick}
          disabled={isEnginePowerTouched && isDisabled}
        >
          {calculateText}
        </Button>
      </div>
      <div css={styles.summary()}>
        <div>{summaryText}</div>
        <div css={styles.taxValue()}>{calculatedTax === 0 ? "-" : `${calculatedTax}€`}</div>
      </div>
    </div>
  );
};
