import React, { ChangeEvent, FocusEventHandler, useMemo, useRef } from "react";
import { styles } from "./VoucherEntryFieldLandingPage.style";
import { Input } from "../../../elements/Input";

export interface VoucherInputProps {
  inputName: string;
  placeholderMessage: string;
  error: string;
  submitButtonTestId?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: FocusEventHandler<HTMLInputElement>;
  validated: boolean;
  voucherCheckPending: boolean;
  onSubmit?: () => void;
}

export const VoucherEntryFieldLandingPage: React.FC<VoucherInputProps> = ({
  inputName,
  placeholderMessage,
  error,
  onBlur,
  onChange,
  validated = false,
  voucherCheckPending,
  onSubmit,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  const onInputChange = (e) => {
    onChange(e);
  };

  const onBlurChange = (e) => {
    onBlur(e);
  };

  const shouldRenderLoadingIcon = () => {
    return voucherCheckPending && !validated;
  };

  const VoucherButton = useMemo(
    () => (
      <div css={styles.searchIconWrapper()}>
        {shouldRenderLoadingIcon() && <div data-testid="icon-voucher-loading" css={styles.loadingIcon()} />}
        {validated && <div data-testid="icon-voucher-valid" css={styles.checkIcon()} />}
      </div>
    ),
    [validated, voucherCheckPending]
  );

  return (
    <form css={styles.form()} onSubmit={onFormSubmit}>
      <Input
        css={styles.input(!!error)}
        ref={inputRef}
        name={inputName}
        placeholder={placeholderMessage}
        onChange={onInputChange}
        onBlur={onBlurChange}
        hasError={!!error}
        message={error}
        customEndIcon={VoucherButton}
      />
    </form>
  );
};
