import { useEffect } from "react";

export const useTabOut = (ref, callback, disabled = false) => {
  useEffect(() => {
    const handleTab = (event) => {
      if (ref.current && !disabled && event.key == "Tab" && !ref.current.contains(event.target)) {
        callback(event);
      }
    };
    document.addEventListener("keyup", handleTab);
    return () => {
      document.removeEventListener("keyup", handleTab);
    };
  }, [ref, callback]);
};
